<template>
  <div>
    <b-toast
        toaster="b-toaster-bottom-center"
        variant="light"
        body-class="bg-light"
        v-model="$store.state.toast_show"
        no-close-button
        :append-toast="true">
      <b-icon-check-circle-fill
          v-if="$store.state.toast_variant==='success'"
          variant="success"/>
      <b-icon-x-circle-fill
          v-if="$store.state.toast_variant==='danger'"
          variant="danger"/>
      <span class="ml-2 text-dark font-weight-bold d-inline">
        {{ $store.state.toast_message }}
      </span>
    </b-toast>
  </div>
</template>
<script>
export default {
  name: "GlobalToast",
};
</script>