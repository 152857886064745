import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";
import store from "../store";

Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "Home",
    component: Home,
  },
  {
    path: "/stock",
    name: "Stock",
    component: () => import("../views/Stock.vue"),
  },
  {
    path: "/basket_orders",
    name: "BasketOrders",
    component: () => import("../views/BasketOrders.vue"),
  },
  {
    path: "/dubai_orders",
    name: "DubaiOrders",
    component: () => import("../views/DubaiOrders.vue"),
  },
  {
    path: "/russia_orders",
    name: "RussiaOrders",
    component: () => import("../views/RussiaOrders.vue"),
  },
  {
    path: "/customer_payments",
    name: "CustomerPayments",
    component: () => import("../views/CustomerPayments.vue"),
  },
  {
    path: "/sales_invoices",
    name: "SalesInvoices",
    component: () => import("../views/SalesInvoices.vue"),
  },
  {
    path: "/catalogs",
    name: "Catalogs",
    component: () => import("../views/Catalogs.vue"),
  },
  {
    name: "404",
    path: "/404",
    component: () => import("../views/PageNotFound"),
  },
  {
    path: "*",
    redirect: "404",
  },
];

const router = new VueRouter({
  mode: "history",
  base: "/",
  routes,
});
router.beforeEach((to, from, next) => {
  const current_path = to.name;
  const username = store.state.username;

  if (username) {
    if (current_path === "Home") {
      next({name: "Stock"});
    } else {
      next();
    }
  } else {
    if (current_path !== "Home") {
      next({name: "Home"});
    } else {
      next();
    }
  }
});
export default router;