<template>
  <div>
    <b-modal id="login_modal" size="sm" hide-backdrop hide-footer hide-header>
      <b-form ref="form" @submit.prevent="handleSubmit" class="text-center">
        <b-img src="https://tnm-files.fra1.cdn.digitaloceanspaces.com/solo/common-files/solo-logo-black.png"
               height="50" w="100" class="mb-4"/>

        <InputText name="İstifadəçi:"
                   :required="true"
                   v-model="username"
                   type="text"
                   class="mb-2"/>
        <InputText name="Şifrə: "
                   :required="true"
                   v-model="password"
                   type="password"
                   class="mb-4"/>

        <b-button type="submit"
                  class="border shadow-sm"
                  style="background-color: #F8C84A;color: #343C42"
                  variant="light">Sistemə Daxil Ol
        </b-button>

      </b-form>
    </b-modal>
  </div>
</template>

<script>
import InputText from "@/components/InputText";

export default {
  components: {InputText},
  data() {
    return {
      username: null,
      password: null
    };
  },
  methods: {
    handleSubmit() {
      const data = {username: this.username, password: this.password};
      this.$store.dispatch("LOGIN", data);
    },
  },
};
</script>