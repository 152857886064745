<template>
  <!-- center both horizontally and vertically -->
  <div class="flex flex-col justify-center items-center text-center">
    <LoginModal />
    <GlobalToast />
    <div>
      <b-img
        src="https://tnm-files.fra1.cdn.digitaloceanspaces.com/solo/common-files/solo-logo-black.png"
        alt="tnm_logo"
        class="mt-5 mb-5"
        height="120"
      />
      <h1 class="mb-5 font-weight-bold">
        Ehtiyat Hissələrin B2B Satışı
      </h1>

      <b-button
        class="shadow shadow-sm border-0 font-weight-bold px-4 py-2 my-3"
        v-b-modal.login_modal
        variant="light"
        style="background-color: #F8C84A;color: #343C42"
      >
        Sistemə Daxil ol
      </b-button>
      <!--        CONTACTS -->
      <div class="mt-5">
        <div style="color:#343C42" class="mt-5 mb-4">
          <b-icon-shop font-scale="1.5" class="bg-transparent" />
          <span
            style="color:#5E6C77;font-size: 16px;"
            class="ml-3 bg-transparent"
            >Əcəmi Naxçıvani 169, Binəqədi ray, Bakı</span
          >
        </div>
        <div style="color:#343C42" class="mb-4">
          <b-icon-envelope font-scale="1.5" class="bg-transparent" />
          <span
            style="color:#5E6C77;font-size: 16px;"
            class="ml-3 bg-transparent"
            >info@soloavto.az</span
          >
        </div>
        <div style="color:#343C42" class="mb-2">
          <b-icon-telephone-forward-fill
            font-scale="1.5"
            class="bg-transparent"
          />
          <span
            style="color:#5E6C77;font-size: 16px;"
            class="ml-3 bg-transparent"
            >+994 70 600 27 56</span
          >
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import LoginModal from "@/components/LoginModal";
import GlobalToast from "@/components/GlobalToast"; // @ is
export default {
  name: "Home",
  components: { GlobalToast, LoginModal },
};
</script>
