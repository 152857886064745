import Vue from "vue";
import Vuex from "vuex";
import axios from "axios";
import router from "@/router";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    section: null,
    username: localStorage.getItem("username") || null,
    app_version: localStorage.getItem("app_version") || null,
    success: null,
    disabled: false,
    toast_show: false,
    toast_variant: null,
    toast_message: null,
    checked:false,
  },
  mutations: {
    SET_SECTION(state, section) {
      state.section = section;
      // document.title = section;
    },
    SET_GLOBAL_USER(state, value) {
      localStorage.setItem("username", value);
      state.username = value;
    },
    SET_APP_VERSION(state, value) {
      localStorage.setItem("app_version", value);
      state.app_version = value;
    },
    DELETE_GLOBAL_USER(state) {
      localStorage.clear();
      state.username = null;
    },
    TOGGLE_DISABLED(state) {
      state.disabled = !state.disabled;
    },
    FORCE_TOGGLE_DISABLED(state) {
      state.disabled = false;
    },
    SET_GLOBAL_TOAST(state, data) {
      state.toast_show = true;
      state.toast_variant = data.success ? "success" : "danger";
      state.toast_message = data.message;
    }
  },
  actions: {
    LOGIN({commit}, data) {
      axios.post("/login", data).then(result => {
        const app_version = result.headers.app_version;
        const username = result.data.username;
        commit("SET_GLOBAL_TOAST", result.data);
        if (result.data.success) {
          commit("SET_GLOBAL_USER", username);
          commit("SET_APP_VERSION", app_version);
          router.push({name: "Stock"});
        }
      });

    },
    LOGOUT({commit}) {
      axios.get("/logout").then((result) => {
        commit("DELETE_GLOBAL_USER");
        commit("SET_GLOBAL_TOAST", result.data);
        router.push({name: "Home"});
      });
    },
    TOGGLE_CHECKED({commit}) {
      axios.get("/products/toggle_checked").then((result) => {
        commit("DELETE_GLOBAL_USER");
        commit("SET_GLOBAL_TOAST", result.data);
        router.push({name: "Home"});
      });
    },
  }
});