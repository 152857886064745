import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import * as dayjs from "dayjs";


import axios from "axios";
import {
    BIconBasket,
    BIconCart4,
    BIconCheckCircleFill,
    BIconEnvelope,
    BIconPencilSquare,
    BIconPersonCircle,
    BIconSearch,
    BIconShop,
    BIconTelephoneForwardFill,
    BIconXCircleFill,
    BootstrapVue,
} from "bootstrap-vue";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import {ErrorInterceptor, ResponseInterceptor} from "../Interceptors";


// ICONS
Vue.component("BIconPersonCircle", BIconPersonCircle);
Vue.component("BIconPencilSquare", BIconPencilSquare);
Vue.component("BIconSearch", BIconSearch);
Vue.component("BIconXCircleFill", BIconXCircleFill);
Vue.component("BIconCheckCircleFill", BIconCheckCircleFill);
Vue.component("BIconCart4", BIconCart4);
Vue.component("BIconEnvelope", BIconEnvelope);
Vue.component("BIconShop", BIconShop);
Vue.component("BIconTelephoneForwardFill", BIconTelephoneForwardFill);
Vue.component("BIconBasket", BIconBasket);


Vue.filter("format_date", (value) => {
    if (!value) return "";
    return dayjs(value.substring(0, 26)).format("YYYY-MM-DD  HH:mm:ss");
});
Vue.use(BootstrapVue);

axios.interceptors.response.use(ResponseInterceptor, ErrorInterceptor);

Vue.config.productionTip = false;
 
new Vue({
    router,
    store,
    render: h => h(App)
}).$mount("#app");