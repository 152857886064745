<template>
  <b-input-group class="rounded shadow-sm">
    <b-input-group-prepend v-if="name">
      <div
        class="pt-1 px-2 bg-light border font-weight-normal text-dark rounded-left"
      >
        {{ name }}
      </div>
    </b-input-group-prepend>
    <b-input
      v-model="my_value"
      :type="type ? type : 'text'"
      :placeholder="placeholder ? placeholder : ''"
      :required="!!required"
      trim
      class="border"
      autocomplete="off"
    />
  </b-input-group>
</template>
<script>
export default {
  name: "InputText",
  props: ["name", "value", "required", "type", "placeholder"],
  computed: {
    my_value: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit("input", val);
      },
    },
  },
};
</script>
