import store from "@/store";
import router from "@/router";

const ResponseInterceptor = (response) => {
  const url = response.config.url;
  const app_version = response.headers.app_version;

  if (url !== "/login" && url !== "/logout" && localStorage.getItem("app_version") !== app_version) {
    store.commit("SET_APP_VERSION", app_version);
    location.reload();
  }
  return response;
};

const ErrorInterceptor = (error) => {
  console.log("ERROR");
  let status = error.response ? error.response.status : "UNKNOWN_ERROR";
  if (status === 401) {
    store.commit("DELETE_GLOBAL_USER");
    router.push({name: "Home"});
  }
  if (status === 404) {
    router.push({name: "404"});
  }
  store.commit("FORCE_TOGGLE_DISABLED");
  return Promise.reject("INTERCEPTED ERROR!!!");
};

export {ResponseInterceptor, ErrorInterceptor};